import React from "react";
import ReactPlayer from "react-player/youtube";

function HoneyBees2({ setCntrlCom }) {
  return (
    <div className="modal-bg">
      <div className="home-vid-size">
        <button
          className="btn-close"
          onClick={() => {
            setCntrlCom();
          }}
        >
          X
        </button>
        <div>
          <p className="textTitle">
            <h2 style={{ color: "white" }}>London Farm Honey Bees - pt 2</h2>
          </p>
        </div>
        <div>
          <div className="home-player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=iCwUcxZFnPU"
              fs="0"
              width="100%"
              height="100%"
              controls={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default HoneyBees2;
