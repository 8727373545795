import React, { useState } from "react";
//import ContactCntrlr from "../Support/ContactCntrlr.js";
//import ContactUs from "../Support/ContactUs.js";
//import ContactUsDone from "../Support/ContactUsDone.js";
import HomeBtns from "./HomeBtns.js";
import PlanVisit from "./PlanVisit.js";
import HoneyBees1 from "./HoneyBees1";
import HoneyBees2 from "./HoneyBees2";

import "./home.css";

function Home() {
  const [cntrlCom, setCntrlCom] = useState();

  function chgComponent(cmpnt) {
    setCntrlCom(cmpnt);
    console.log(cntrlCom);
  }
  /*return <ContactCntrlr setCntrlCom={setCntrlCom} />;
              case "planvisit":*/
  return (
    <div className="">
      <div className="homeContainer">
        <div className="teaflex" style={{ marginBottom: "0px" }}>
          <img
            className="teaimage"
            style={{ width: "30em", height: "auto:" }}
            src={process.env.PUBLIC_URL + "/events/" + "teaservice.jpg"}
            alt="Tea Service"
          ></img>
        </div>
        <div className="">
          <HomeBtns chgComponent={chgComponent} />
        </div>

        <div className="">
          {(() => {
            switch (cntrlCom) {
              case "planvisit":
                return <PlanVisit setCntrlCom={setCntrlCom} />;
              case "honeyBees1":
                return <HoneyBees1 setCntrlCom={setCntrlCom} />;
              case "honeyBees2":
                return <HoneyBees2 setCntrlCom={setCntrlCom} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
}

export default Home;
